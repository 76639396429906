// Variables
$color-primary: #2870EA;
$color-secondary: #4e4e4e;

$color-text: #4e4e4e;
$color-text-secondary: #000;

$color-bg: rgba(255,255,255,0.7);


.footer{
    width: 100%;
    height: 100px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h3{
        font-size: 18px;
        font-weight: 500;
    }

    .footer-right{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        a{
            text-decoration: none;
            color: #4e4e4e;
            font-size: 18px;
            font-weight: 400;

            margin-left: 20px;

            &:hover{
                color: $color-primary;
                transition: 0.3s ease-in-out;
            }
        }
    }
}