// Variables
$color-primary: #2870EA;
$color-secondary: #4e4e4e;

$color-text: #4e4e4e;
$color-text-secondary: #000;

$color-bg: rgba(255,255,255,0.7);

$background-primary: linear-gradient(130deg, #2870EA 20%, #1B4AEF 77.5%);


.landing-page{
    width: 96%;
    height: 100%;
    margin: 0 auto;

    .jobs-container{
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }    

    .paging-container{
        width: 100%;
        height: 46px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        border: 2px solid #4e4e4e;
        border-left: none;
        border-right: none;

        margin-top: 20px;

        button {
            width: 100px;
            height: 36px;
            font-size: 18px;
            
            border-radius: 8px;
            border: 1px solid transparent;
            background-color: #4e4e4e;

            font-size: 18px;
            font-weight: 500;
            color: #fff;

            cursor: pointer;

            &:hover{
                background-color: #fff;
                border: 2px solid $color-primary;
                color: $color-primary;
            }
        }

        h4{
            font-size: 20px;
            font-weight: 600;
            color: #4e4e4e;

            span {
                font-weight: 400;
            }
        }
    }

}