// Variables
$color-primary: #2870EA;
$color-secondary: #4e4e4e;

$color-text: #4e4e4e;
$color-text-secondary: #000;

$color-bg: rgba(255,255,255,0.7);

$background-primary: linear-gradient(130deg, #2870EA 20%, #1B4AEF 77.5%);


.App {
  width: 90%;
  height: 100%;
  margin: 0 auto;

  max-width: 1200px;

  background: linear-gradient(90deg, rgb(239, 242, 247) 0%, 7.60286%, rgb(237, 240, 249) 15.2057%, 20.7513%, rgb(235, 239, 248) 26.297%, 27.6386%, rgb(235, 239, 248) 28.9803%, 38.2826%, rgb(231, 237, 249) 47.585%, 48.1216%, rgb(230, 236, 250) 48.6583%, 53.1306%, rgb(228, 236, 249) 57.6029%, 61.5385%, rgb(227, 234, 250) 65.4741%, 68.7835%, rgb(222, 234, 250) 72.093%, 75.7603%, rgb(219, 230, 248) 79.4275%, 82.8265%, rgb(216, 229, 248) 86.2254%, 87.8354%, rgb(213, 228, 249) 89.4454%, 91.8605%, rgb(210, 226, 249) 94.2755%, 95.4383%, rgb(209, 225, 248) 96.6011%, 98.3005%, rgb(208, 224, 247) 100%);
  color: $color-text;

  box-shadow: 0px 0px 4px 1px rgba(31, 31, 31, 0.1);
}

.btn-primary {
  background: $background-primary;
  color: #fff;

  border: 2px solid transparent;
  border-radius: 8px;

  font-size: 18px;
  font-weight: 600;

  cursor: pointer;

  &:hover {
    border: 2px solid $color-primary;
    background: #fff;
    color: $color-primary;
  }
}

.mid-div{
  width: 100%;
  height: 100%;

  margin: 50px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
